import React, { useState } from "react";
import './video-content-search.css';
import close from '../../../assets/icons/icons8-cancel-30.png';

const VideoContentSearch: React.FC<{ onSearch: (query: string) => void }> = ({ onSearch }) => {
    const [query, setQuery] = useState<string>('');

    const handleSearch = () => {
        onSearch(query);
    };

    const handleCancel = () => {
        setQuery('');
        onSearch('');
    };

    return (
        <div className="video-search-wrapper">
            <div className="video-search-controls">
                <input
                    type="text"
                    placeholder="Search videos..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="video-search-input"
                />
                {query && <span onClick={handleCancel}><img src={close} /></span>}
            </div>

            <button onClick={handleSearch} className="video-search-button">
                Search
            </button>
        </div>
    );
};

export default VideoContentSearch;
