import React from 'react';
import './video-content-tile.css';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { StaticImage } from 'gatsby-plugin-image';

type CaseStudyTileProps = {
    to: string;
    title: string;
    headline: string;
    image: string;
    width?: string;
};

const VideoContentTile: React.FC<CaseStudyTileProps> = ({ to, title, headline, image, width }) => {
    console.log(image)
    return (
        <Link to={`/videos${to}`} className={'case-study-tile'} target='_blank' style={{ width: width }}>
            <div className="image-wrapper" style={{ height: "100%", minWidth: "300px" }}>
                <img src={image} alt={`AptEdge | ${title}`} width={"100%"}/>
            </div>

            <div className="case-study-tile-text-wrapper">
                <h3 className={'text-md bold teal'}>{title}</h3>
                {/* <div className="case-study-tile-details-wrapper flex text-black" style={{ gap: "1em", opacity: "0.45" }}>
                    <p className="text-sm  case-study-tile-author medium" dangerouslySetInnerHTML={{ __html: author }}>
                    </p>
                </div> */}
                <p className={"text-black"} dangerouslySetInnerHTML={{ __html: headline }}></p>
            </div>
        </Link>
    );
};

export default VideoContentTile;
