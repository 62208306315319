import React, { useState } from 'react';
import CtaBlock from '../components/components-group-one/CtaBlock';
import HtmlHead from '../components/html-head';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { graphql } from 'gatsby';
import { PageData } from '../types';
import videoContentData from '../data/videocontentData';
import VideoContentPageTiles from '../components/video-content-listing/video-content-pagetiles';
import VideoContentHero from '../components/video-resource-components/video-content-hero';

const VideoListing: React.FC<PageData> = (resp) => {
    const seoData = resp?.data?.allContentfulPageSeoData?.edges[0]?.node;
    const [filteredVideos, setFilteredVideos] = useState(videoContentData.resourceData);
    

    const handleSearch = (query: string) => {
        const filtered = videoContentData.resourceData.filter(video =>
            video.caseStudyTitle.toLowerCase().includes(query.toLowerCase()) ||
            video.title.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredVideos(filtered);
    };

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <Navbar />
            <div className="blog-page-bg">
                <VideoContentHero onSearch={handleSearch} />
                <VideoContentPageTiles posts={{ resourceData: filteredVideos }} />
                <Footer />
            </div>
        </>
    );
};

export default VideoListing;

export const query = graphql`
    query VideosQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/videos" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;
