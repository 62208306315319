import React from 'react';
import './video-content-hero.css';
import VideoContentSearch from '../video-content-search';

const VideoContentHero: React.FC<{ onSearch: (query: string) => void }> = ({ onSearch }) => {
    return (
        <section className="hero-section blog-hero-section">
            <div className="container">
                <div className="blog-hero-wrapper">
                    <h1 className="text-3xl bold teal animate blog-h1">Videos</h1>
                    <p className='text-lg text-black description animate'>Take a deeper dive into the future of AI in customer service with case studies.</p>
                    <div className='animate form-parent'>
                        <VideoContentSearch onSearch={onSearch} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VideoContentHero;
