import GeraldThumbnail from '../assets/resources/Gerald Video Page.jpg';
import CharlesThumbnail from '../assets/resources/Charles Video Page.jpg';

const videoContentData = {
    resourceData: [
        {
            id: 'venafi',
            link: '/empower-agents-ai-boosting-productivity/',
            caseStudyTitle: 'Navigating Challenges and Future Trends in Customer Service',
            thumbnail: GeraldThumbnail,
            title: 'Check out the latest interview of Aakrit Prasad (Co-Founder & CEO) and Charles Coaxum, Former Head of Customer Success Security at Cisco, an early adopter of AptEdge on "The Future Of Customer Service".',
        },
        {
            id: 'hexagon',
            link: '/fireside-chat-future-customer-support/',
            caseStudyTitle: ' Fireside Chat; Future of Support',
            thumbnail: CharlesThumbnail,
            title: 'Check out the latest interview of Aakrit Prasad (Co-Founder & CEO) and Gerald Hastie, Customer Support Leader, a Strategic Advisor to Aptedge on "Reframing Support"',
        }
    ],
}

export default videoContentData;